<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">

                    <el-form-item label="业主验收单">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
                            :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_files"
                            :before-remove="remove_files" :file-list="m.filesFile">
                            <el-button size="mini" type="primary">点击上传</el-button>

                        </el-upload>
                    </el-form-item>

                    <el-form-item label="效果图片">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
                            :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_imageUrl"
                            :before-remove="remove_imageUrl" :file-list="m.imageUrlFile" list-type="picture-card">
                            <el-button size="mini" type="primary">点击上传</el-button>

                        </el-upload>
                    </el-form-item>

                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                         
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
//import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    //components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                projectId: [],
                imageUrl: [],
                files: [],
            },
            fileList: [],
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改 交付效果";
                data.imageUrlFile = JSON.parse(data.imageUrl);
                data.filesFile = JSON.parse(data.files);
                this.m = data;
            } else {
                this.m = {
                    projectId: data.projectId,
                    imageUrl: '',
                    files: '',
                    filesFile:[],
                    imageUrlFile:[],
                    category: data.category
                }
                this.title = "添加交付效果";
            }
        },
        success_imageUrl(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.imageUrlFile) {
                this.m.imageUrlFile = [];
            } this.m.imageUrlFile.push(response.data);
            console.log(fileList);
        },
        remove_imageUrl(file, fileList) {
            this.m.imageUrlFile = fileList;
        },
        success_files(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.filesFile) {
                this.m.filesFile = [];
            } this.m.filesFile.push(response.data);
            console.log(fileList);
        },
        remove_files(file, fileList) {
            this.m.filesFile = fileList;
        },

        //提交交付效果信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.m.imageUrl = JSON.stringify(this.m.imageUrlFile.map(item => {
                        let a = {};
                        a.name = item.name;
                        a.url = item.url;
                        return a;
                    }));
                    this.m.files = JSON.stringify(this.m.filesFile.map(item => {
                        let a = {};
                        a.name = item.name;
                        a.url = item.url;
                        return a;
                    }));
                    console.log("提交之前呀", this.m);

                    this.sa.post("/deliver/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
    },
};
</script>